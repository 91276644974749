* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: sans-serif;
  user-select: none;
}

div[role='group'] {
  margin-bottom: 8px;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  font-size: 20px;
}

form {
  max-width: min(550px, 90vw);
}

label {
  margin-bottom: 4px;
  font-size: 15px;
  display: inline-block;
}

input {
  padding: 8px 12px;
  width: 100%;
  font-size: 16px;
  display: block;
  background-color: whitesmoke;
  border: none;
  border-radius: 4px;
}

button {
  margin-top: 4px;
  padding: 8px 12px;
  width: 100%;
  font-size: 16px;
  color: white;
  background-color: blue;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

dialog {
  min-width: 300px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  padding: 24px;
}

dialog button {
  margin-top: 16px;
}

dialog h2 {
  margin: 0;
  font-size: 20px;
}

.logo {
  margin: 0 auto;
  width: 200px;
  display: block;
}

.App {
  text-align: center;
}

.red-hat-display-onTRACK{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 750;
  font-style: normal;
  color: white;
}

.red-hat-display-section{
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  color: black;
}
